import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

/**
 * The http client service to send form input submissions to formspree email API.
 */
@Injectable()
export class SendMailService {

  constructor(private http: HttpClient) { }

  public sendMail(url, data): Subscription {
    const headers = new HttpHeaders();
    headers.set('Content-Type', '');
    return this.http.post(url, data, {'headers' : headers}).subscribe(() => {});
  }

}
