import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  template: `<div class="progress-bar-container">
    <div class="progress-bar {{color}}"
    [ngStyle]="{'width': progress + '%'}">{{progress | number : '1.0-0'}}%
    </div>
  </div>`,
  styleUrls: ['./progress-bar.component.css']
})
export class ProgressBarComponent implements OnInit {
  @Input() progress: number;
  @Input() total: number;
  color: string;

  constructor() { }
  ngOnInit() {
    if (!this.progress) {
      this.progress = 0;
    }
    if (this.total === 0) {
      this.total = this.progress;
    } else if (!this.total) {
      this.total = 100;
    }
    if (this.progress > this.total) {
      this.progress = 100;
      this.total = 100;
    }
    this.progress = (this.progress / this.total) * 100;
    if (this.progress < 50) {
      this.color = 'red';
    } else if (this.progress < 100) {
      this.color = 'orange';
    } else {
      this.color = 'green';
    }
  }
}
