import { Component, ElementRef, HostListener, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { QuizService } from '../../services/quiz.service';
import { Option, Question, Quiz, QuizConfig } from '../../models';
import { EncrDecrService } from '../../services/encr-decr.service';
import { HelperService } from '../../services/helper.service';
import { SendMailService } from '../../services/send-mail.service';
import { MatDialog } from '@angular/material';
import { FormBuilder, Validators } from '@angular/forms';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.css'],
  providers: [QuizService]
})
export class QuizComponent implements OnInit {
  @ViewChild('resultDetails', { static: false }) resultDetails: ElementRef;
  quizes: any[];
  quiz: Quiz = new Quiz(null);
  mode = 'notStarded';
  quizName: string;
  config: QuizConfig = {
    'allowBack': true,
    'autoMove': false,
    'timeLimitedPerQuestion': 60,
    'pageSize': 1,
    'requiredAll': true,
    'shuffleQuestions': true,
    'shuffleOptions': true,
    'showPager': true,
  };

  pager = {
    index: 0,
    size: 1,
    count: 1
  };
  timer: any = null;
  timeStartedForAllQuestions: Date;
  timeStartedPerQuestion: Date;
  timeUsedForAllQuestions = '00:00';
  timeUsedPerQuestion = '00:00';
  timeLimitedPerQuestion = '';
  fulstacLogoSrc = 'assets/images/fulstac.png';
  fulstacLogoAlt = 'Fulstac Logo';
  candidateInfosForm = this.formBuilder.group({
    name: ['', Validators.required],
    email: ['', Validators.compose([Validators.required, Validators.email])],
    phoneNumber: ['', Validators.required]
  });
  activeSubmitCandidateInfosButton: boolean;
  submittedCandidateInfos: boolean;
  submittedTest: boolean;

  @HostListener('input') oninput() {
    if (this.candidateInfosForm.valid) {
      this.activeSubmitCandidateInfosButton = true;
    }
  }

  constructor(private quizService: QuizService, private encrDecrService: EncrDecrService,
              private helperService: HelperService, private dialog: MatDialog,
              private sendMailService: SendMailService, private formBuilder: FormBuilder,
              private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.quizes = this.quizService.getAll();
    this.quizName = this.quizes[0].id;
  }

  loadQuiz(quizName: string): void {
    this.quizService.get(quizName).subscribe(res => {
      this.quiz = new Quiz(this.encrDecrService.decryptJson(res));
      if (this.config.shuffleQuestions) {
        this.quiz.questions = this.helperService.shuffle(this.quiz.questions);
      }
      if (this.config.shuffleOptions) {
        this.quiz.questions.forEach(q => q.options = this.helperService.shuffle(q.options));
      }
      this.pager.count = this.quiz.questions.length;
    });
  }

  countdown(): void {
    const now = new Date();
    const timeDiffPerQuestion = (now.getTime() - this.timeStartedPerQuestion.getTime()) / 1000;
    const timeDiffForAllQuestions = (now.getTime() - this.timeStartedForAllQuestions.getTime()) / 1000;
    this.timeUsedPerQuestion = this.parseTime(timeDiffPerQuestion);
    this.timeUsedForAllQuestions = this.parseTime(timeDiffForAllQuestions);
    if (timeDiffPerQuestion >= this.config.timeLimitedPerQuestion) {
      if (this.pager.index + 1 !== this.pager.count) {
        this.goTo(this.pager.index + 1);
      } else {
        if (!this.submittedTest) {
          this.onSubmitAnswers();
        }
      }
    }
  }

  parseTime(totalSeconds: number): string {
    let mins: string | number = Math.floor(totalSeconds / 60);
    let secs: string | number = Math.round(totalSeconds % 60);
    mins = (mins < 10 ? '0' : '') + mins;
    secs = (secs < 10 ? '0' : '') + secs;
    return `${mins}:${secs}`;
  }

  get filteredQuestions(): Question[] {
    return (this.quiz.questions) ? this.quiz.questions.slice(this.pager.index, this.pager.index + this.pager.size) : [];
  }

  onSelect(question: Question, option: Option): void {
    if (question.questionTypeId === 1) {
      question.options.forEach((x) => { if (x.id !== option.id) {x.selected = false; }});
    }

    if (this.config.autoMove) {
      this.goTo(this.pager.index + 1);
    }
  }

  goTo(index: number): void {
    if (index >= 0 && index < this.pager.count) {
      this.pager.index = index;
    }
    this.timeStartedPerQuestion = new Date();
  }

  isCorrect(question: Question): boolean {
    return question.options.every(x => x.selected === x.isAnswer);
  }

  onSubmitCandidateInfos(): void {
    this.submittedCandidateInfos = true;
  }

  onSubmitAnswers(): void {
    const answers = [];
    this.quiz.questions.forEach(x => answers.push({ 'quizId': this.quiz.id, 'questionId': x.id, 'answered': x.answered }));
    this.mode = 'finished';
    this.changeDetectorRef.detectChanges();
    const url = 'https://formspree.io/f/mbjwykya';
    const data: FormData = new FormData();
    data.append('Candidate Name', this.candidateInfosForm.value.name);
    data.append('Candidate Email', this.candidateInfosForm.value.email);
    data.append('Candidate Phone Number', this.candidateInfosForm.value.phoneNumber);
    data.append('Candidate Test Skill', this.quiz.name);
    data.append('Candidate Test Score', `${this.getTotalCorrectAnswers(this.quiz.questions)}/${this.quiz.questions.length}`);
    this.setTestResultPdfForDataToSend(data);
    this.submittedTest = true;
    setTimeout(() => {
      this.sendMailService.sendMail(url, data);
    }, 5000);
  }

  getTotalCorrectAnswers(questions: Question[]): number {
    return questions.filter(q => this.isCorrect(q)).length;
  }

  startQuiz(quizName: string): void {
    this.mode = 'started';
    this.loadQuiz(quizName);
    this.timeStartedForAllQuestions = this.timeStartedPerQuestion = new Date();
    this.timeUsedForAllQuestions = this.timeUsedPerQuestion = '00:00';
    this.timeLimitedPerQuestion = this.parseTime(this.config.timeLimitedPerQuestion);
    this.timer = setInterval(() => { this.countdown(); }, 0);
  }

  setTestResultPdfForDataToSend(data: FormData): void {
    html2canvas(this.resultDetails.nativeElement).then((canvas) => {
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      heightLeft -= pageHeight;
      const doc = new jspdf('p', 'mm');
      doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        heightLeft -= pageHeight;
      }
      data.append('Candidate Test Result PDF', new File([doc.output('blob')], 'testResult.pdf'));
    });
  }
}
