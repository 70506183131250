import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { QuizComponent } from './components/quiz/quiz.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';

import { EncrDecrService } from './services/encr-decr.service';
import { HelperService } from './services/helper.service';
import { SendMailService } from './services/send-mail.service';

import { BlockCopyPasteDirective } from './directives/block-copy-paste.directive';

@NgModule({
  declarations: [
    AppComponent,
    QuizComponent,
    ConfirmationDialogComponent,
    ProgressBarComponent,
    BlockCopyPasteDirective
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatDialogModule,
    ReactiveFormsModule
  ],
  entryComponents: [ConfirmationDialogComponent],
  providers: [EncrDecrService, HelperService, SendMailService],
  bootstrap: [AppComponent]
})
export class AppModule { }
