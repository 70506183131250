import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class QuizService {

  constructor(private http: HttpClient) { }

  get(url: string): Observable<any> {
    return this.http.get(url);
  }

  getAll(): any[] {
    return [
      { id: 'data/java.json', name: 'Java' },
      { id: 'data/javaSpringBoot.json', name: 'Java Spring Boot' },
      { id: 'data/cSharp.json', name: 'C#' },
      { id: 'data/javascript.json', name: 'JavaScript' },
      { id: 'data/python.json', name: 'Python' },
      { id: 'data/php.json', name: 'Php' },
      { id: 'data/sql.json', name: 'Sql' }
    ];
  }

}
