import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})

export class EncrDecrService {
  key: string;
  constructor() {
    this.key = 'can not be decrypted';
  }
  decryptJson = (content: any) => JSON.parse(CryptoJS.AES.decrypt(content, this.key).toString(CryptoJS.enc.Utf8)).content;
  encryptJson = (content: any) => CryptoJS.AES.encrypt(JSON.stringify({ content }), this.key).toString();
}
